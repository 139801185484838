.ant-table-thead>tr>th {
    color: #ffffff;
    font-weight: 500;
    text-align: left;
    background: $primary-color;
    border-bottom: 1px solid $primary-color;
    padding: 6px 16px !important;
    // position: sticky;
    top: -1px;
    z-index: 10;
   
    .ant-table-column-sorters {
        padding: 0 !important;
        // &:hover {
        //     background-color: $primary-color !important;
        // }
    }
}

.table, tr {
     page-break-inside: avoid;
   
 }


.color-status {
    width: 16px;
    height: 16px;
    display: block;
    border-radius: 100%;
    margin: 0 auto;
}

.icon-group {
    span {
        font-size: 18px;
        color: #298a96;
    }
}

.ant-table-tbody {
    tr {
        td {
            padding: 6px 16px !important;

            .if-reaudit {
                align-items: center;
                justify-content: center;
                margin-right: 6px;
                display: none;
                span {
                  position: relative;
                  top: -2px;
                  font-size: 16px;
                }
              }
        }
        &:nth-child(2n + 2) {
            td {
                background-color: #edf4f5;
            }
        }
        &.warning-row {
            td {
                background-color: #ffe7e7 !important;
                border-top: solid 1px #e08484;
                border-bottom: solid 1px #e08484;
                &:first-child {
                    border-left: solid 1px #e08484;
                }
                &:last-child {
                    border-right: solid 1px #e08484;
                }
                .warning-info {
                    span {
                        color: #a96060 !important;
                    }
                }
                // &:first-child {
                //     background-image: url(../../icons/warning-dark.svg);
                //     background-repeat: no-repeat;
                //     background-position: 8px center;
                //     padding-left: 37px;
                //     background-size: 17px;
                // }
            }
            .if-reaudit { 
                display: inline-flex;
            }
        }
    }
}

.custom-card {
    .ant-card-body {
        padding: 0 !important;
    }
}

.status-count {
    color: #ec7468;
    font-size: 13px;
    &.pc {
        color: #4893bf;
    }
    &.bnc {
        color: #4dc59f;
    }
}

.icon-card {
    width: 18px;
}