.at-ov {
    &-wrapper {
      display: flex;
      gap: 16px;
      padding: 24px;
      height: 100%;
      background: #eaeef3;
      overflow: auto;
    }
  
    &-card {
      &-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        .count {
          display: flex;
          flex-wrap: wrap;
          gap: 16px;
  
          .at-ov-card {
            display: flex;
            align-items: center;
            border: 0px #e5e5e5 solid;
            cursor: pointer;
            &:hover {
              box-shadow: 0px 8px 16px #00000010;
              transform: scale(1.01);
              transition: 0.3s ease all;
            }
            .content {
              flex: 1 1 auto;
              margin: 0;
              p {
                margin: 0;
                font-size: 20px;
                font-weight: 500;
              }
              text {
                font-size: 14px;
                font-weight: 500;
              }
            }
            .status-icon {
              flex: 0 0 auto;
              padding: 12px;
              border: 0px #e5e5e5 solid;
              border-radius: 8px;
              height: 48px;
              width: 48px;
              text-align: center;
              color: #fff;
              display: grid;
              &.red {
                background: #dc3064;
              }
              &.blue {
                background: #1e96dc;
              }
              &.orange {
                background: #f48c2c;
              }
              &.green {
                background: #05bc59;
              }
            }
          }
        }
        .chart {
          margin-top: 16px;
          height: 300px;
          .at-ov-card {
            height: 100%;
          }
          canvas{
            width: 100% !important;
          }
        }
      }
      flex: 1 0;
      width: 100%;
      // min-width: 300px;
      box-sizing: border-box;
      padding: 24px;
      border: 0px solid transparent;
      border-radius: 8px;
      background: #fff;
    }
  
    &-alert {
      &-wrapper {
        background-color: #fff;
        flex: 0 0 300px;
        border: 0px solid transparent;
        box-shadow: 0px 0px 16px #00000010;
        border-radius: 8px;
        position: relative;
        overflow: auto;
        h5 {
          margin: 0;
          padding: 16px;
          background: #fff;
          position: sticky;
          top: 0;
        }
      }
      &-item {
        padding: 16px;
        border-bottom: 1px dashed #e5e5e5;
        p {
          margin: 0;
  
          &:first-child {
            color: #555555;
            font-weight: 500;
          }
          &:nth-child(2) {
            color: #777777;
          }
        }
        &.unread {
          background: rgba($primary-color, 0.1);
          border-left: 3px solid orangered;
        }
      }
    }
  }
  
  .at-chart-detail {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 16px;
    padding: 24px;
    table {
      width: 100%;
    }
  }

  