.is-accordion {
    &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 8px 46px 8px 16px !important;
        background: #f3f3f3;
        font-size: 15px;
        &[aria-expanded="true"] {
            background-color: $primary-color;
            font-weight: bold;
            color: #fff; 
        }
        &:hover {
            background-color: $primary-color;
            color: #fff;
        }
    }
    &.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        left: inherit;
        right: 23px; 
    }
    &.ant-collapse > .ant-collapse-item {
        background: #fff !important;
        box-shadow: 0px 2px 3px rgba(#000, .06);
        margin-bottom: 8px;
        border-bottom: 0 !important;
    }
    .ant-collapse-content {
        border-top: 0 !important;
    }
    &.ant-collapse {
        border:0 !important
    }
    .ant-collapse-item {
        &:hover {
            background-color: $primary-color;
            color:  $primary-color;
        }
    }
}
.content-group{
    transition: all 0.3s ease;
    li {
        list-style: none;
        padding: 8px 12px;
        margin-bottom: 0px;
        border-top: dashed 1px transparent;
        border-left: dashed 1px transparent;
        border-right: dashed 1px transparent;
        border-bottom: dashed 1px #e4e4e4;
        font-size: 15px;
        
        .anticon  {
            font-size: 16px;
            position: relative;
            top: -2px;
        }
    }

    &-draggable {
        &:hover {
            border: dashed 1px $primary-color;
            cursor: move;
            color:  $primary-color;
        }
    }
}