.ant-layout-header {
    background: linear-gradient(to right, #21a299, #329dde);
    height: auto;
    line-height: normal;
    padding-top: 6px;
    padding-bottom: 6px;
}
.logo {
    img {
        width: 100%;
    }
    &.is-text {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;
    }
}

.avathar{
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border:solid 1px $primary-color;
    overflow: hidden;
    span {
            color: $primary-color;
            font-size: 22px;
        }
}
.avathar img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.login-text {
    line-height: 18px;
    font-size: 16px;
    span {
        display: block;
        font-weight: 400;
        font-size: 12px;
    }
}
.menu-a {
    color: #fff;
    font-weight: bold;
    align-items: start !important;
    &:hover {
        text-decoration: none;
        color: #fff;

    }
}
