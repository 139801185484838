.ant-input,
.ant-picker {
  height: 33px;
  border-radius: $border-radius;
  width: 100%;
}
.ant-form-item {
  margin-bottom: 16px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 33px;
  border-radius: $border-radius;
}
.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 33px;
}

.ant-form-item-label > label {
  color: darken($primary-color, 7%);
  font-size: 15px;
  font-weight: 500;
}
.ant-btn:hover,
.ant-btn:focus {
  color: $primary-color;
  border-color: $primary-color;
}
.ant-form-item-control-input {
  .ant-select-clear {
    top: 44% !important;
  }
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.custom-search {
  height: 42px;
  border-radius: $border-radius;
  > * {
    height: 100%;
  }
}

.custom-text-area-input {
  min-height: 100px !important;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: #43b4c2;
  box-shadow: none;
}

.card-user:hover {
  transition: all 0.1s;
  transform: scale(1) !important;
  box-shadow: none !important;
  border: solid 1px #ccc !important;
}

.ant-layout-sider {
  max-height: 100%;
  overflow: auto;
  background: transparent;
  overflow-x: hidden;
}
.ant-card {
  box-shadow: 0px 0px 9px #00003330;
  padding: 16px 16px;
  border-radius: 10px;
  border: solid 1px #ccc;
}
.current-status {
  padding: 2px 16px;
  border-radius: 10px;
  color: #fff;
  font-size: 12px;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  display: none;
}

.custom-field {
  height: 33px;
  border-radius: $border-radius;
  input {
    height: 100%;
  }
}
.ant-input-affix-wrapper:hover {
  border-color: $primary-color !important;
  border-right-width: 1px !important;
  box-shadow: none !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: $primary-color !important;
  border-right-width: 1px !important;
  box-shadow: none !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: $primary-color !important;
  border-right-width: 1px !important;
  box-shadow: none !important;
}
.pointer {
  cursor: pointer;
}
.text-ellipse {
  text-overflow: ellipsis;
  overflow: hidden;
}

.form-popup {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 18px;
  color: #000;
}

.ant-modal-content {
  border-radius: 10px;
  overflow: hidden;
}

