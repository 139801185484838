.no-data-group {
    .no-data-image {
        img {
            margin: 0 auto;
        }
    }
    h2 {
        font-size: 32px;
        font-weight: bold;
        margin: 0;
        p {
            font-size: 18px;
            font-weight: normal;
        }
    }
    p {
        font-size: 18px;
    }
}