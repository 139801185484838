.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-color: #d9d9d9;
  &.ant-btn-primary {
    background-color: $primary-color;
    border-color: $primary-color;
    color: #fff !important;
    &:hover {
      background-color: darken($primary-color, 8%);
      color: #fff !important;
    }
  }

  &-icon-only {
    padding: 0;
  }

  input {
    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
  .form-control:focus {
    box-shadow: none !important;
    border-color: $primary-color !important;
  }
}

.custom-btn {
  font-size: 16px;
  height: 40px;
  border-radius: $border-radius;
}

.ant-btn {
  font-size: 16px;
  height: 33px;
  border-radius: 4px;
  span {
    position: relative;
    top: -2px;
    &.anticon {
      top: -4px;
    }
  }
}

.ant-input-search-button {
  background-color: $primary-color;
  color: #fff !important;
  &:hover {
    background: darken($primary-color, 8%);
  }
}
.custom-icon-btn,
.ant-input-search-button {
  width: 48px;
  span {
    position: relative;
    &.anticon {
      top: 0px;
    }
  }
}

.btn-close{
  padding: 4px;
  margin-left: 8px;
  color: #4d4d4d;
}
