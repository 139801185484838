.login-page {
background-image: url(../../../Images/login-bg.jpg);
background-repeat: no-repeat;
background-position: center center;
background-size: cover;
position: relative;
&:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($primary-color , .9);
    z-index: 1;
}
>* {
    position: relative;
    z-index: 2;
}
}
.login-group {
    width: 380px;
    border-radius: $border-radius;
    box-shadow: 0px 0px 15px rgba(#000, .3);
    border: solid 1px $primary-color;
}
.brand-logo {
    h2 {
        font-size: 18px;
        text-align: center;
        margin: 0 0 23px 0px;
    }
}
