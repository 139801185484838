.ant-layout-sider {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-right: 1px solid #e5e5e5;
  padding: 16px 0px;
  background: #fff;
}

.ant-menu-submenu-selected {
  color: $primary-color !important;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: auto !important;
  margin: 0 !important;
  padding: 8px;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: auto !important;
  // border-bottom: dashed 1px #cccc !important;
  border-radius: 8px;
  margin: 0;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  padding-left: 16px !important;
  background-color: transparent;
  font-weight: 600;
  color: #021e1c !important;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid darken($primary-color, 8%);
}

.ant-menu-submenu-title {
  padding-left: 16px !important;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #021e1c !important;
}
.ant-menu-submenu-vertical
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from($primary-color),
    to($primary-color)
  ) !important;
  background: linear-gradient(
    to right,
    $primary-color,
    $primary-color
  ) !important;
}

.menu-group {
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  .ant-layout-sider-children {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    > ul {
      height: 100%;
      overflow: auto;
      overflow-x: hidden;
    }
  }
}

.ant-menu-submenu{
  .ant-menu{
    padding-left: 32px !important;
  }
}

.ant-menu-item{
  padding-left: 8px !important;
}

.ant-menu-sub.ant-menu-inline{
  background: transparent;
}

.ant-menu-submenu-selected{
  background: rgba($primary-color, 0.08);
}

.custom-menu-item{
  display: flex;
  align-items: baseline;
  padding-right: 4px;
  p{
    margin: 0;
    flex: 1;
  }
}