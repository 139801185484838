html,
body {
  height: 100%;
  > iframe{
    display: none;
  }
}
* {
  margin: 0;
  padding: 0;
  outline: none;
}
#root {
  height: 100%;
}

/* Let's get this party started */

::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background: #b9b9b9;
  border-radius: 20px;
}

::-webkit-scrollbar-track
{
	background-color: #f5f5f500;
}


.ant-layout {
  background: #eaeef3;
}

.page-title {
  // height: 80px;
  // background: #104950;
  background: white;
  // display: flex;
  padding: 12px 24px;
  // background-image: url(../../../Images/title-bg.jpg);
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center center;
  // position: relative;
  // text-align: center;
  h1 {
    // color: #fff;
    font-size: 18px;
    margin: 0;
  }
  // &:after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   background-color: rgba($primary-color-darken, 0.9);
  //   z-index: 1;
  // }
  // > * {
  //   position: relative;
  //   z-index: 2;
  // }
  // + * {
  //   position: relative;
  //   z-index: 2;
  // }
  // &.height-auto {
  //   height: auto;
  // }
}

.form-group-area {
  border-radius: 10px;
  box-shadow: 0px 0px 11px #0003;
  // border: solid 7px #fff;
}

.ant-menu-inline {
  .ant-menu-item {
    padding: 6px 16px;
    overflow: visible !important;
    line-height: 20px !important;
    text-overflow: inherit !important;
    white-space: normal !important;
    word-break: break-all;
    .ant-menu-submenu-title {
      .ant-menu-item {
        padding: 6px 20px !important;
      }
    }
  }
}

.question-p {
  font-size: 18px;
  text-align: center;
  margin-bottom: 26px;
  padding-bottom: 10px;
  border-bottom: dashed 1px #ccc;
}

.group-item {
  p {
    margin-bottom: 16px;
    padding-bottom: 8px;
    border-bottom: dashed 1px #ccc;
    strong {
      font-size: 15px;

      color: #516365;
      font-weight: 600;
    }
  }
}
.ant-modal-title {
  color: $primary-color;
  font-size: 18px;
}
.min-select-width {
  min-width: 200px;
}
.ant-form-item-control-input {
  .ant-select-clear {
    display: none;
  }
}
// .icon-view-report {
//   border-right: dashed 1px #ccc;
//   margin-left: 5px;
// }

.text-primary {
  color: $primary-color !important;
}
.dl-list {
  position: relative !important;
}
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #22747d !important;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #dff2f5;
}
.ant-table-thead th.ant-table-column-sort {
  background: #22747d !important;
}
.ant-table-column-sorter {
  color: #fff;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: $primary-color !important;
}
.ant-menu-item a:hover {
  color: $primary-color;
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: $primary-color;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: $primary-color;
}
.ant-pagination-item-active {
  border-color: $primary-color;
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: $primary-color;
  border-color: $primary-color;
}
.ant-table-thead th.ant-table-column-sort {
  background: #f5f5f5;
}

.ant-modal-body{
  max-height: 500px;
  overflow: auto;
}
.mb-16{
  margin-bottom: 16px;
}

.h-100{
  height: 100%;
}
.overflow-auto{
  overflow: auto;
}

.overflow-x{
  overflow-x: auto;
}

.overflow-y{
  overflow-y: auto;
}

.flex-auto{
  flex: 0 0 auto;
}

.flex-fill{
  flex: 1 1 auto;
}

.custom-form-item {
  cursor: default;
}

.ant-spin-nested-loading{
  height: 100% !important;
  .ant-spin-container {
    height: 100% !important;
  }
}