.refresh-popup {
    .ant-modal-body {
        padding-top: 10px;
       
    }
    .list-team {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        gap: 8px;
        li {
            list-style-position: inside;
            padding: 4px 8px;
            list-style: none;
            background: #f2feff;
            border-radius: 4px;
            border: solid 1px #72acb3;
            display: inline-block;
            // width: calc(100% / 3);
            font-size: 13px;
         
        }
    }
    .ant-form-item-label{
        padding-bottom: 2px;
    }
}


.ant-card-small > .ant-card-head {
    background: #ececec;
    // color: darken($primary-color , 9%);
    color: #4a4a4a;
}
.ant-card {
    overflow: hidden;
    color: #424242;
}